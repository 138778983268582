export enum FacilityMainActivityCode {
  ONE_A = '1(a)',
  ONE_B = '1(b)',
  ONE_C = '1(c)',
  TWO_B = '2(b)',
  TWO_C = '2(c)',
  TWO_C_I = '2(c)(i)',
  TWO_C_III = '2(c)(iii)',
  TWO_D = '2(d)',
  TWO_E = '2(e)',
  TWO_E_I = '2(e)(i)',
  TWO_E_II = '2(e)(ii)',
  TWO_F = '2(f)',
  THREE_A = '3(a)',
  THREE_B = '3(b)',
  THREE_C = '3(c)',
  THREE_C_I = '3(c)(i)',
  THREE_C_II = '3(c)(ii)',
  THREE_E = '3(e)',
  THREE_F = '3(f)',
  THREE_G = '3(g)',
  FOUR_A = '4(a)',
  FOUR_A_I = '4(a)(i)',
  FOUR_A_II = '4(a)(ii)',
  FOUR_A_IV = '4(a)(iv)',
  FOUR_A_IX = '4(a)(ix)',
  FOUR_A_VIII = '4(a)(viii)',
  FOUR_A_X = '4(a)(x)',
  FOUR_B = '4(b)',
  FOUR_B_I = '4(b)(i)',
  FOUR_B_II = '4(b)(ii)',
  FOUR_B_IV = '4(b)(iv)',
  FOUR_B_V = '4(b)(v)',
  FOUR_C = '4(c)',
  FOUR_D = '4(d)',
  FOUR_E = '4(e)',
  FOUR_F = '4(f)',
  FIVE_A = '5(a)',
  FIVE_B = '5(b)',
  FIVE_C = '5(c)',
  FIVE_D = '5(d)',
  FIVE_E = '5(e)',
  FIVE_F = '5(f)',
  FIVE_G = '5(g)',
  SIX_A = '6(a)',
  SIX_B = '6(b)',
  SIX_C = '6(c)',
  SEVEN_A = '7(a)',
  SEVEN_A_I = '7(a)(i)',
  SEVEN_A_II = '7(a)(ii)',
  SEVEN_A_III = '7(a)(iii)',
  EIGHT_A = '8(a)',
  EIGHT_B = '8(b)',
  EIGHT_B_I = '8(b)(i)',
  EIGHT_B_II = '8(b)(ii)',
  EIGHT_C = '8(c)',
  NINE_A = '9(a)',
  NINE_C = '9(c)',
  NINE_E = '9(e)'
}
