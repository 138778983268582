export enum PollutantCodeAir {
  ASANDCOMPOUNDS = 'ASANDCOMPOUNDS',
  BENZENE = 'BENZENE',
  CDANDCOMPOUNDS = 'CDANDCOMPOUNDS',
  CH4 = 'CH4',
  CHLORINEANDINORGANICCOMPOUNDS = 'CHLORINEANDINORGANICCOMPOUNDS',
  CO = 'CO',
  CO2 = 'CO2',
  CO2EXCLBIOMASS = 'CO2EXCLBIOMASS',
  CRANDCOMPOUNDS = 'CRANDCOMPOUNDS',
  CUANDCOMPOUNDS = 'CUANDCOMPOUNDS',
  DCE_1_2 = 'DCE-1,2',
  DCM = 'DCM',
  DEHP = 'DEHP',
  FLUORINEANDINORGANICCOMPOUNDS = 'FLUORINEANDINORGANICCOMPOUNDS',
  HCB = 'HCB',
  HCH = 'HCH',
  HCN = 'HCN',
  HFCS = 'HFCS',
  HGANDCOMPOUNDS = 'HGANDCOMPOUNDS',
  N2O = 'N2O',
  NAPHTHALENE = 'NAPHTHALENE',
  NH3 = 'NH3',
  NIANDCOMPOUNDS = 'NIANDCOMPOUNDS',
  NMVOC = 'NMVOC',
  NOX = 'NOX',
  NPANDNPES = 'NPANDNPES',
  PAHS = 'PAHS',
  PBANDCOMPOUNDS = 'PBANDCOMPOUNDS',
  PCBS = 'PCBS',
  PCDD_PCDF_DIOXINS_FURANS = 'PCDD+PCDF(DIOXINS+FURANS)',
  PENTACHLOROBENZENE = 'PENTACHLOROBENZENE',
  PER = 'PER',
  PHENOLS = 'PHENOLS',
  PM10 = 'PM10',
  SOX = 'SOX',
  TCB = 'TCB',
  TCM = 'TCM',
  TOC = 'TOC',
  TRI = 'TRI',
  TRICHLOROMETHANE = 'TRICHLOROMETHANE',
  VINYLCHLORIDE = 'VINYLCHLORIDE',
  ZNANDCOMPOUNDS = 'ZNANDCOMPOUNDS'
}

export enum PollutantCodeWater {
  ASANDCOMPOUNDS = 'ASANDCOMPOUNDS',
  BENZENE = 'BENZENE',
  CDANDCOMPOUNDS = 'CDANDCOMPOUNDS',
  CHLORIDES = 'CHLORIDES',
  CHLORINEANDINORGANICCOMPOUNDS = 'CHLORINEANDINORGANICCOMPOUNDS',
  CRANDCOMPOUNDS = 'CRANDCOMPOUNDS',
  CUANDCOMPOUNDS = 'CUANDCOMPOUNDS',
  CYANIDES = 'CYANIDES',
  DCE_1_2 = 'DCE-1,2',
  DCM = 'DCM',
  DEHP = 'DEHP',
  FLUORANTHENE = 'FLUORANTHENE',
  FLUORIDES = 'FLUORIDES',
  HALOGENATEDORGANICCOMPOUNDS = 'HALOGENATEDORGANICCOMPOUNDS',
  HCB = 'HCB',
  HCH = 'HCH',
  HCN = 'HCN',
  HGANDCOMPOUNDS = 'HGANDCOMPOUNDS',
  N2O = 'N2O',
  NAPHTHALENE = 'NAPHTHALENE',
  NIANDCOMPOUNDS = 'NIANDCOMPOUNDS',
  NOX = 'NOX',
  NPANDNPES = 'NPANDNPES',
  OCTYLPHENOLSANDOCTYLPHENOLETHOXYLATES = 'OCTYLPHENOLSANDOCTYLPHENOLETHOXYLATES',
  ORGANOTINCOMPOUNDS = 'ORGANOTINCOMPOUNDS',
  PAHS = 'PAHS',
  PBANDCOMPOUNDS = 'PBANDCOMPOUNDS',
  PCBS = 'PCBS',
  PCDD_PCDF_DIOXINS_FURANS = 'PCDD+PCDF(DIOXINS+FURANS)',
  PENTACHLOROBENZENE = 'PENTACHLOROBENZENE',
  PER = 'PER',
  PHENOLS = 'PHENOLS',
  TOTALNITROGEN = 'TOTALNITROGEN',
  TOTALPHOSPHORUS = 'TOTALPHOSPHORUS',
  TCB = 'TCB',
  TCM = 'TCM',
  TOC = 'TOC',
  TRI = 'TRI',
  TRICHLOROMETHANE = 'TRICHLOROMETHANE',
  VINYLCHLORIDE = 'VINYLCHLORIDE',
  ZNANDCOMPOUNDS = 'ZNANDCOMPOUNDS'
}